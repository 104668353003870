@import '../scss/variables';
@import '../scss/responsiveness';
@import '../scss/typography';
@import '../scss/layout';

@import '../scss/components/Tag';

.proposalPage {
    @include mainPadding;
    @include minPageHeight;

    max-width: 1100px;
    margin: 0 auto;

    &__image {
        max-height: 35vh;
        width: 100%;
        object-fit: cover;
        border-radius: $border-radius;
    }
    &__row {
        display: flex;
        flex-direction: column;
        margin-top: $spacing-s;

        @include breakpoint(tablet-landscape-up) {
            flex-direction: row;
            margin-top: $spacing-l;
            justify-content: space-between;
        }
    }
    &__column {
        max-width: 650px;
    }
    &__paragraph--bold {
        @include p--bold;
        margin-bottom: $spacing-xs;
    }
    &__status {
        background-color: $overlay-01;
        padding: $spacing-s;
        margin: $spacing-m auto;
        border-radius: $border-radius;
        max-width: 500px;

        display: flex;
        flex-direction: column;

        h4 {
            margin-bottom: $spacing-xxs;
        }
        @include breakpoint(tablet-landscape-up) {
            margin-top: 0;
        }
        .tag {
            margin: 0 0 $spacing-s 0;
            align-self: center;
            &:nth-of-type(2) {
                margin: 0 0 $spacing-xxs 0;
            }
        }
        label {
            @include p--tiny;
            margin-bottom: 0;
        }
    }
    &__statusTag {
        align-self: center;
    }
    &__proposalDetails {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: $spacing-s;
    }
    &__details {
        margin-bottom: $spacing-xs;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    &__label {
        @include p--bold;
        &--center {
            text-align: center;
            margin-bottom: $spacing-xxs;
        }
    }
    &__accountID {
        @include h4;
        transition: all 0.2s ease-in;
        &--link {
            &:hover {
                color: $orange-medium;
            }
        }
    }
    &__amount {
        @include h3;
    }
}