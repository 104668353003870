@import '../scss/variables';
@import '../scss/responsiveness';
@import '../scss/typography';

.filter {
    margin: $spacing-xs 0;
    &__label {
        @include p--tiny;
        margin-bottom: $spacing-xxs;
    }
    &__group {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        input[type=checkbox] {
            position: absolute;
            clip: rect(0,0,0,0);
            pointer-events: none;

            &:checked + label {
                border: 1px solid $orange-gradient;
                color: $orange-light !important;
                background-color: $orange-background;
            }
        }

        @include breakpoint(tablet-up) {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
        }


    }
    &__item {
        /* removes default checkbox/radio element */
        input[type="checkbox"],
        input[type="radio"] {
            position: absolute;
            clip: rect(0,0,0,0);
            pointer-events: none;
        }

        padding: $spacing-xxxs $spacing-xs !important;
        border: 1px solid $overlay-01 !important;
        border-radius: 20px !important;

        color: $typography-02 !important;
        text-align: center;

        margin-left: $spacing-xxs !important;
        transition: all 0.2s ease-in;

        background: transparent;

        flex: 0 1 auto !important;
    }
}
