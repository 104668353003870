@import '../scss/variables';
@import '../scss/typography';
@import '../scss/responsiveness';
@import '../scss/layout';

.footer {
    @include mainPadding;
    background-color: $modal-background-color;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: $spacing-s !important;
    padding-bottom: $spacing-s !important;

    @include breakpoint(tablet-landscape-up) {
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }

    &__socialMediaIcon {
        margin-right: $spacing-s;
        padding: 10px;
        width: 44px;
        height: 44px;

        &:last-of-type {
            margin-right: 0;
        }

        &:hover {
            svg #background {
                fill: url(#gradient--reverse);
            }
        }
    }
    &__images {
        display: flex;
        flex-direction: column;
    }
    &__builtWithLove,
    &__poweredByWAX {
        max-width: 130px;
    }
    &__poweredByWAX {
        margin-bottom: $spacing-s;
    }
    &__disclaimer {
        margin: $spacing-xxs 0;
        text-align: center;
    }
    &__waxLabs {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: $spacing-s 0;

        @include breakpoint(tablet-landscape-up) {
            margin: 0 $spacing-m;
        }

        img {
            width: 100px;
            margin-bottom: 0;

            @include breakpoint(tablet-landscape-up) {
                margin-bottom: $spacing-xxs;
            }
        }
    }
    &__legal {
        display: flex;
        gap: $spacing-s;
    }
    &__legalLink {
        color: $typography-02;

        &:hover {
            color: $typography-01;
            text-decoration: underline;
        }
    }
}
