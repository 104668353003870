@import '../../scss/typography';
@import '../../scss/variables';

@import '../../scss/components/Button.scss';
@import '../../scss/components/Modal.scss';

.adminMenu {
    padding: $spacing-m;
    background-color: $overlay-02;
    border-radius: $border-radius;

    margin-bottom: $spacing-m;

    text-align: center;

    @include breakpoint(tablet-landscape-up) {
        text-align: start;
    }

    &__actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: $spacing-s 0;

        @include breakpoint(tablet-landscape-up) {
            flex-direction: row;
            flex-wrap: wrap;
        }

        &--row {
            flex-direction: column-reverse;

            @include breakpoint(tablet-landscape-up) {
                flex-direction: row;
                align-items: flex-start;

                margin-top: $spacing-s;
            }
        }

        .button {
            margin: $spacing-s 0 0 0;

            @include breakpoint(tablet-landscape-up) {
                margin: 0 $spacing-m 0 0;
            }
        }
    }
    &__modalAction {
        float: right;
        margin-top: 16px;
    }

    &__reject-body, &__askchanges-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .input__label {
            margin-top: $spacing-s;
        }

        @include breakpoint(tablet-landscape-up) {
            margin-top: 0;
        }

        .input {
            background-color: $overlay-01;
        }

        .button {
            margin-top: $spacing-xs;
        }

        textarea {
            display: flex;
            width: 100%;
            max-width: 243px;
        }
    }

    &__reject-header, &__askchanges-header {
        .accordion-button {
            @include p--bold;
            border-radius: 100px;
            padding: 10px $spacing-s; //guarantees height of 44px at least
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            border: none;
            transition: all 0.2s ease-in-out;

            background-repeat: no-repeat;
            background-position-y: center;
            background-position-x: right;

            background-position-x: calc(100% - #{$spacing-s});
            background-size: 20px;

            color: rgba(255, 255, 255, 0.85);

            @include breakpoint(desktop-up) {
                padding: $spacing-xxs $spacing-s;
            }

            &:hover {
                text-decoration: none;
            }

            &:disabled {
                opacity: 0.4;
                cursor: default;
            }
        }
    }

     &__reject-header .accordion-button {
        background-image: url('../../images/dislike.svg');
        background-color: $red-dark;
        padding-right: 47px !important;

        &:hover {
            background-color: $red-medium;
            color: rgba(255, 255, 255, 0.85)
        }
        &:disabled {
            &:hover {
                background-color: $red-dark;
                color: rgba(255, 255, 255, 0.85);
            }
        }
    }

    &__askchanges-header .accordion-button {
        background-image: none;
    	background-color: $orange-dark;
    	padding-right: $spacing-s;
    	&:hover {
      	    background-color: $orange-medium;
            color: rgba(255, 255, 255, 0.85);
        }
        &:disabled {
            &:hover {
                background-color: $orange-dark;
                color: rgba(255, 255, 255, 0.85);
            }
        }
    }
    &__accordion {
        display: flex;
        flex-direction: column;

        @include breakpoint(tablet-up) {
            flex-direction: row;
            gap: $spacing-s;
        }
    }

    &__accordionContent {
        display: flex;
        flex-direction: column;
        min-width: 280px;
    }
}
