@import '../variables';

.carousel {
    margin: $spacing-m auto;
    max-height: 60vh;
    max-width: 70vw;

    &-control-next,
    &-control-prev {
        z-index: 10;
    }

    &-inner {
        border-radius: $border-radius;
        max-height: 60vh;
        max-width: 70vw;

    }
    &__caption {
        background-color: rgba($color: $gray-dark, $alpha: 0.65);
        border-radius: $border-radius;

        padding: $spacing-s 15%;
        bottom: $spacing-l;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        z-index: 1;

        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .tag {
            margin-bottom: $spacing-s;
        }
    }

}