@import '../../scss/variables';
@import '../../scss/responsiveness';
@import '../../scss/typography';
@import '../../scss/layout';

.manageCategories {
    @include mainPadding;
    @include minPageHeight;

    &__fieldset {
        display: flex;
        flex-direction: column;
        gap: $spacing-xs;

        margin-bottom: $spacing-l;

        min-width: 250px;

        .button {
            align-self: center;
        }
    }
    &__requirements {
        margin: $spacing-s 0;
        p {
            @include p--bold;
            margin-bottom: $spacing-xxs;
        }
    }
    &__requirementsList {
        @include p;
        list-style-position: inside
    }
}
