@use "sass:math";

@import './responsiveness';
@import './variables';

$baseFontSize: 16px;

@function pxToRem($size) {
  $remSize: math.div($size, $baseFontSize);
  @return #{$remSize}rem;
}

@font-face {
    font-family: "Open Sans";
    src: url("./../fonts/OpenSans-Bold.ttf") format("ttf");
    font-weight: 700;
}
@font-face {
    font-family: "Open Sans";
    src: url("./../fonts/OpenSans-Regular.ttf") format("ttf");
    font-weight: 400;
}
@font-face {
    font-family: "Montserrat";
    src: url("./../fonts/Montserrat-ExtraBold.ttf") format("ttf");
    font-weight: 800;
}
@font-face {
    font-family: "Montserrat";
    src: url("./../fonts/Montserrat-Bold.ttf") format("ttf");
    font-weight: 700;
}
@font-face {
    font-family: "Montserrat";
    src: url("./../fonts/Montserrat-SemiBold.ttf") format("ttf");
    font-weight: 600;
}

@mixin headline-font-face {
    font-family: 'Montserrat', sans-serif;
    color: $typography-01;
}
@mixin body-font-face {
    font-family: 'Open Sans', sans-serif;
    color: $typography-02;
}

@mixin h1 {
    @include headline-font-face();
    font-size: pxToRem(33px);
    font-weight: 800;
    line-height: 130%;

    @include breakpoint (desktop-up) {
        font-size: pxToRem(39px);
    }
}

@mixin h2 {
    @include headline-font-face();
    font-size: pxToRem(27px);
    font-weight: 700;
    line-height: 130%;

    @include breakpoint (desktop-up) {
        font-size: pxToRem(31px);
    }
}

@mixin h3 {
    @include headline-font-face();
    font-size: pxToRem(23px);
    font-weight: 600;
    line-height: 130%;
    @include breakpoint (desktop-up) {
        font-size: pxToRem(25px);
    }
}

@mixin h4 {
    @include headline-font-face();
    font-size: pxToRem(18px);
    font-weight: 600;
    line-height: 130%;
    @include breakpoint (desktop-up) {
        font-size: pxToRem(20px);
    }
}

@mixin p {
    @include body-font-face();
    font-size: pxToRem(16px);
    font-weight: 400;
    line-height: 150%;
}

@mixin p--bold {
    @include p;
    font-weight: 700;
}

@mixin p--tiny {
    @include body-font-face();
    color: $typography-tiny;
    font-weight: 700;
    font-size: pxToRem(14px);
}

h1 {
    @include h1;
    margin-bottom: $spacing-xs;
}
h2 {
    @include h2;
    margin-bottom: $spacing-xs;
}
h3 {
    @include h3;
    margin-bottom: $spacing-xs;
}
h4 {
    @include h4;
    margin-bottom: $spacing-xs;
}
p,
span {
    @include p;
    margin-bottom: $spacing-xs;

    .tiny {
        @include p--tiny;
    }
    .bold {
        @include p--bold;
    }
}