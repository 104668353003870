@import "../variables";
@import "../typography";
@import "../responsiveness";

@mixin user-input {
    @include p;
    background-color: $gray-dark;
    border-radius: 8px;
    border: 1px solid $overlay-02;
    padding: $spacing-xxs;

    box-shadow: none;
    outline: none;
    width: 100%;

    &::placeholder {
        color: $typography-03;
    }
    &:active,
    &:focus {
        border: 1px solid $overlay-06;
    }
}

.input {
    @include user-input;

    &--error {
        border-color: $red-medium;
    }
}

.input__errorMessage {
    @include p--bold;
    color: $red-light;
}

.textarea {
    @include user-input;
    min-height: 150px;

    &--error {
        border-color: $red-medium;
    }
}

.input__label {
    @include p--tiny;
    color: $typography-02;
    margin-bottom: $spacing-xxs;
    white-space: nowrap;
}