@import '../../scss/typography';
@import '../../scss/variables';

@import '../../scss/components/Link.scss';
@import '../../scss/components/Button.scss';
@import '../../scss/components/Input.scss';

.proposerMenu {
    padding: $spacing-m;
    background-color: $overlay-02;
    border-radius: $border-radius;

    margin-bottom: $spacing-m;

    text-align: center;

    @include breakpoint(tablet-up) {
        text-align: start;
    }

    &__header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        margin: $spacing-xs 0;

        p {
            margin: 0 $spacing-m 0 0;
        }

        @include breakpoint(large-mobile-only) {
            flex-direction: column;
            .button {
                margin-top: $spacing-xs;
            }
            p {
                align-self: flex-start;
            }
        }
    }
    &__body {
        max-width: 500px;
    }
    &__actions {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include breakpoint(tablet-up) {
            flex-direction: row;
        }

        .button {
            margin: $spacing-s 0 0 0;
            @include breakpoint(tablet-up) {
                margin: 0 $spacing-m 0 0;
            }
        }
    }
    &__accordion {
        margin: $spacing-s 0;
    }
    &__accordionContent {
        margin-top: $spacing-xs;
    }
}