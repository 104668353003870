@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import './scss/variables';
@import './scss/typography';

* {
    padding: 0;
    margin: 0;
  }

html {
  height: 100%;
}

h2 {
  @include h2;
}

body {
  padding: 0;
  margin: 0;
  color: #333;
  font-family: 'Muli', sans-serif;
  height: 100%;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
}


header, footer, div {
  box-sizing: border-box;
}

header .nav-wrapper {
  display: grid;
  grid-template-rows: [row]auto[end];
  grid-template-columns: [logo]201px[nav]auto[accName];
}

header {
  background: #183648;
  color: #fff;
  padding: 0 10px;
  border-bottom: 1px solid #000;
}

header .logo {
  padding-top: 19px;
  grid-row-start: row;
  grid-row-end: span end;
  grid-column-start: logo;
  grid-column-end: span nav;
}
header .header-link {
  width: 100%;
}

header .logo-text {
  width: 100%;
  white-space: nowrap;
  vertical-align: middle;
  font-size: 1.3em;
}

header .logo img {
  vertical-align: middle;
}

header .logo a {
  color: #fff;
  text-decoration: none;
}

header .logo a:hover {
  text-decoration: none;
}

header .menu-wrapper {
  grid-row-start: row;
  grid-row-end: span end;
  grid-column-start: nav;
  grid-column-end: span accName;
  text-align: right;
}

header nav {
  display: inline-block;
  vertical-align: top;
  height: 100%;
}

header nav ul {
  height: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-block;
}

header nav ul li {
  display: inline-block;
  padding: 6px 0px;
  margin: 0;
  vertical-align: middle;
}

header nav ul li:hover {
  cursor: pointer;
  background: #85b1bb;
}

header nav img {
  width: 32px;
  height: 32px;
  display: block;
  margin: 0 auto;
}

header .login-wrapper button {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center right;
  background-color: transparent;
  color: #fff;
  border: 0;
  font-size: 1.2em;
  height: 100%;
  width: 100%;
}

body header .login-wrapper button:hover {
    cursor: pointer;
}


header .login button:focus {
  cursor: pointer;
  border: 0;
  outline: none;
}

header nav ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 0.9em;
  padding: 8px;
  display: inline-block;
  vertical-align: middle;
}

header nav a:hover {
  color: #fff;
  background: #85b1bb;
}

header .login-wrapper {
  text-align: center;
  display: inline-block;
  padding: 14px 0 13px 8px;
  vertical-align: middle;
}

header .login-wrapper.mobile {
  display: none;
}

header .login-li button {
  font-size: 0.9em;
  color: #fff;
  padding: 8px;
  display: inline-block;
  border: 0;
  cursor: pointer;
}

header .notifications {
  position: relative;
  padding: 6px 8px;
}

header .notifications .bell {
  background: url('./images/no-notifications.png');
  background-size: cover;
  width: 32px;
  height: 32px;
  position: relative;
  margin: 16px 0;
  border: 0;
}

header .notifications .bell.has-notifications {
  background: url('./images/notifications.png');
  background-size: cover;
  width: 32px;
  height: 32px;
  position: relative;
  border: 0;

}

header .notifications .bell.has-notifications .count {
  visibility: visible;
}

header .notifications .bell:hover {
  cursor: pointer;
}

header .notifications .bell .count {
  position: absolute;
  top: 1px;
  right: 5px;
  color: #fff;
  font-size: 10px;
  visibility: hidden;
}

header .notifications .notifications-submenu {
  height: auto;
  display: block;
  position: absolute;
  background: #85b1bb;
  top: 100%;
  right: 0;
  border-right: 1px solid #000;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  color: #000;
  margin-top: 1px;
  z-index: 10;
  padding: 0;
}

header .notifications .notifications-submenu li {
  min-width: 175px;
  font-size: 0.8em;
  position: relative;
  z-index: 12;
  padding: 0;
}

header .notifications .notifications-submenu li a {
  background:#85b1bb;
  padding: 18px;
  display: block;
  transition: background 0.4s, color 0.4s, border 0.4s;
}

header .notifications .notifications-submenu li a:hover {
  background:#fff;
}

header .notifications .notifications-submenu li a span.label {
  color: #fff;
  position: relative;
  z-index: 12;
  top: 7px;
  right: 4px;
  opacity: 0.7;
}

header .notifications .notifications-submenu li a:hover span.label {
  color: #000;
}

header .notifications .notifications-submenu li a span.count {
  display: block;
  position: absolute;
  color: #426C76;
  font-size: 3em;
  right: 10px;
  top: 5px;
  font-weight: 700;
  z-index: 11;
}

header .notifications .notifications-submenu li a:hover span.count {
  color: #85b1bb;
}

button:focus {
  outline: 0 !important;
}

header .login-li button:focus {
  border: 0;
}

.login-wrapper .accHeader {
  vertical-align: middle;
  display: block;
  font-size: 11px;
  font-weight: 700;
}

.login-wrapper .accName  {
  vertical-align: middle;
  display: block;
}

.login-wrapper .accProfile {
  text-decoration: underline;
  vertical-align: middle;
  font-size: 10px;
  display: inline-block;
  color: #fff;
}

.login-wrapper .logoutBtn {
  text-decoration: underline;
  font-size: 10px;
  vertical-align: middle;
  display: inline-block;
}

.login-wrapper .logoutBtn:hover {
  cursor: pointer;
}

header nav button#menu-icon {
  display: none;
}

#login {
  background: transparent;
}

#login:focus {
  border: 0;
  outline: 0;
}

.hide {
    display: none;
}

.btn {
  background: #F78E1E;
  color: #fff;
  padding: 7px 15px;
  border-radius: 25px;
  border: 2px solid #F78E1E;
  font-size: 16px;
  transition: background 0.4s, color 0.4s, border 0.4s;
  cursor: pointer;
  text-decoration: none;
}

.btn.accept:hover {
  background: #308014;
  border: 2px solid #308014;
  margin: 3px;
}

.btn.decline {
  background: #cc0000;
  border: 2px solid #cc0000;
  margin: 3px;
}

.btn.decline:hover {
  background: #b20000;
  border: 2px solid #b20000;
  margin: 3px;
}

.btn:hover {
  background: #FA7B21;
  border: 2px solid #FA7B21;
}

.deliverables .btn {
  margin-left: 5px;
}

main {
  background-color: $gray-dark;
  // display: grid;
  // grid-template-columns: [start-gutter]auto[main-content]980px[end-gutter]auto[end];
  // grid-template-rows: [start]auto[end];
  // padding: 10px;
}

.content {
  grid-row-start: start;
  grid-row-end: span end;
  grid-column-start: main-content;
  grid-column-end: end-gutter;
}

.content .proposal-grid-single, .content .deliverables-grid-single {
  margin-top: 10px;
  border: 1px solid #333;
  padding-right: 10px;
  display: block;
}

.proposal-grid-single {
  text-decoration: none;
  color: #000;
  padding-right: 10px;
  transition: background 0.4s, color 0.4s, border 0.4s;
}

.proposal-grid-single:hover {
  background: #ebebeb;
  transition: background 0.4s, color 0.4s, border 0.4s;
}

.proposal-grid-single:visited {
  color: #000;
}

.proposal-grid-single .title .description {
  padding-top: 5px;
}

.proposal-grid-single .image {
  display: inline-block;
  width: 25%;
}

.proposal-grid-single .image img {
  width: 100%;
}

.proposal-grid-single .body {
  display: inline-block;
  width: 75%;
  padding: 10px;
  vertical-align: top;
}

.proposal-grid-single .body .row {
  padding: 10px 0;
}

.proposal-grid-single .body .row .cell {
  display: inline-block;
  width: 50%;
}

.proposal-grid-single .body .row .cell .category {
  text-transform: capitalize;
}

.public-account .row {
  padding-bottom: 10px;
}

.public-account .account-info-wrapper .account-image {
  display: inline-block;
}

.public-account .account-info-wrapper .account-info-table {
  display: inline-block;
  vertical-align: top;
  padding-left: 10px;
}

.deliverables-grid-single .image {
  display: inline-block;
  vertical-align: middle;
  width: 25%;
}

.deliverables-grid-single .image img {
  margin-bottom: -4px;
  width: 100%;
}

.deliverables-grid-single .information {
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  width: 42%;
}

.deliverables-grid-single .actions {
  display: inline-block;
  vertical-align: middle;
  padding: 10px 0;
  text-align: right;
  width: 33%;
}

.deliverables .single {
  margin-top: 10px;
  border: 1px solid #333;
  padding: 10px;
}

.deliverables .deliverables-menu, .account-info .account-menu, .admin-content-wrapper .admin-submenu {
  padding: 15px 0;
}

.deliverables .deliverables-menu ul, .account-info .account-menu ul, .admin-content-wrapper .admin-submenu ul {
  list-style: none;
}

.deliverables .deliverables-menu ul li, .account-info .account-menu ul li, .admin-content-wrapper .admin-submenu ul li {
  display: inline-block;
  margin-right: 8px;
}

.deliverables .deliverables-menu ul li a, .account-info .account-menu ul li a, .admin-content-wrapper .admin-submenu ul li a {
  text-decoration: none;
  padding: 5px 8px;
  background-color: #ebebeb;
  border-color: #ebebeb;
  color: #000;
}

.deliverables .deliverables-menu ul li a:hover, .account-info .account-menu ul li a:hover, .admin-content-wrapper .admin-submenu ul li a:hover {
  background-color: #999999;
  border-color: #999;
}

.proposal-grid-single .title .proposal-id {
  font-size: 0.75em;
}

.single-proposal .backend-menu {
  margin-bottom: 10px;
  border: 1px solid #333;
  padding: 10px;
  background: #ebebeb
}

.single-proposal .backend-menu .btn {
  background: #666;
  border: #666;
  padding: 5px 10px;
  margin-right: 5px;
}

.single-proposal .backend-menu .btn:hover {
  background: #333;
}

.single-proposal .backend-menu h3 {
  padding-bottom: 10px;
}

.proposal-header .short-description {
  margin-top: 7px;
  padding-bottom: 10px;
  border-bottom: 1px solid #000;
}

.proposal-header .category {
  text-transform: capitalize;
  font-size: 0.75em;
}

.core-information {
  border-bottom: 1px solid #000;
}

.core-information .row {
  padding: 10px 0;
}

.core-information .row .left-col, .core-information .row .right-col {
  display: inline-block;
  width: 50%;
  box-sizing: border-box;
}

.proposal-body {
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
}

.single-proposal .new-reviewer-panel {
  padding-top: 10px;
}

.single-proposal .new-reviewer-panel div {
  padding-bottom: 10px;
}

.proposals-menu {
  padding: 10px 0;
  display: inline-block;
  vertical-align: top;
}

.proposals-menu .row {
  padding-bottom: 20px;
}

.proposals-menu strong {
  display: block;
  padding-bottom: 10px;
}

.proposals-menu ul {
  list-style: none;
}

.proposals-menu ul li {
  padding-bottom: 5px;
}

.proposals-menu ul li a {
  padding: 5px 8px;
  text-decoration: none;
  display: block;
  text-align: center;
}

.proposals-menu ul li a.current-page {
  background-color: #FA7B21;
}

.deliverables-menu ul li a.current-page {
  background-color: #999;
  border-color: #999;
}

.category-filter ul li {
  text-transform: capitalize;
}

.filtered-proposals {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.proposal-body {
  padding-top: 10px;
}

.proposal-body .voting {
  display: inline-block;
  width: 40%;
}

.proposal-body .proposal-content {
  display: inline-block;
  width: 60%;
}

.account-info .row {
  padding: 10px 0;
}

.account-info {
  max-width: 600px;
  width: 100%;
}

.account-info .profile-picture {
  display: inline-block;
}

.account-info .account-info-table {
  display: inline-block;
  padding-left: 20px;
}

.account-info .row .value {
  padding: 4px 0;
}

.account-info .row input {
  padding: 4px 0;
  box-sizing: border-box;
}

.account-info .row.submit-form {
  text-align: right;
}

.edit-proposal {
  max-width: 600px;
  width: 100%;
}

.edit-proposal-table .row {
  padding: 10px 0;
}

.edit-proposal-table .row.submit-form {
  text-align: right;
}

.edit-proposal-table .row.submit-form .btn, .account-info .row.submit-form .btn, .admin-content-wrapper .row.submit-form {
  margin-left: 5px;
}

.edit-proposal-table .row.submit-form .btn.draft, .account-info .row.submit-form .btn.cancel {
  background-color: #ebebeb;
  border-color: #ebebeb;
}

.edit-proposal-table .row.submit-form .btn.draft:hover, .account-info .row.submit-form .btn.cancel:hover {
  background-color: #999;
  border-color: #999;
}

.edit-proposal-table .row input, .edit-proposal-table .row textarea, .edit-proposal-table .row select, .account-info .row input, .account-info .row textarea, .account-info .row select {
  display: block;
  width: 100%;
  margin-top: 5px;
}

.edit-proposal-table .row input, .edit-proposal-table .row select, .account-info .row input, .account-info .row select {
  height: 1.5em;
  font-size: 1.25em;
}

.edit-proposal-table .row select:not(.country) {
  text-transform: capitalize;
}

.edit-proposal-table .row input {
  padding: 0 4px;
  box-sizing: border-box;
}

.edit-proposal-table .row textarea, .account-info .row textarea {
  height: 300px;
  font-size: 1.25em;
  font-family: 'Muli', sans-serif;
  padding: 4px;
}

.single-proposal .deliverables {
  padding-top: 10px;
}

.single-category {
  padding: 8px 0;
}

.single-category .cat-name {
  text-transform: capitalize;
  display: inline-block;
  width: 200px;
}

.single-category .cat-remove {
  display: inline-block;
}

.single-category .cat-remove .btn {
  background-color: #F00;
  border: 0;
}

.single-category .cat-remove .btn:hover {
  background-color: #cc0000;
  border: 0;
}

.admin-portal .admin-menu {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax( 32% , 1fr));
  grid-template-rows: repeat(auto-fill, 1fr);
}

.admin-content {
  padding-top: 10px;
}

.admin-content .add-cat {
  padding: 10px 0;
}

.admin-content .current_duration, .admin-content .change-duration {
  padding-top: 10px;
}

.admin-portal .admin-dashboard-item {
  display: block;
  border: 2px solid #183648;
  border-radius: 30px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  transition: background 0.4s, color 0.4s, border 0.4s;
  color: #183648;
  padding: 50px 0;
  margin: 10px 1%;
}

.admin-portal .admin-dashboard-item:hover {
  background: #183648;
  color: #fff;
}

.admin-portal .admin-dashboard-item img {
  width: 48px;
  height: 48px;
}

.admin-portal .admin-dashboard-item:hover img {
  width: 48px;
  height: 48px;
  filter: invert(99%) sepia(8%) saturate(313%) hue-rotate(252deg) brightness(116%) contrast(100%);
  transition: filter 0.4s
}

.admin-portal .admin-dashboard-item .label {
  display: block;
}

.alert-dismissible {
  padding-right: $close-font-size + $alert-padding-x * 2;

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: $alert-padding-y $alert-padding-x;

    background-color: transparent;
    border: 0;
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;

    &:after {
      content: '×';
      display: block;
    }
  }
}
