@import '../../scss/variables';
@import '../../scss/responsiveness';

.proposalInputContainer {
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    @include breakpoint(large-mobile-up) {
        width: 450px;
    }

    @include breakpoint(tablet-landscape-up) {
        width: 500px;
    }

    &__fieldset {
        
        flex-direction: column;
        align-items: flex-start;

        margin-bottom: $spacing-s;
    }

    &__totalRequested { 
        display: inline-block;

        .input__label {
            display: inline-block;
            min-width: 48.8%;
        }

        h4 {
            margin-top: $spacing-xxs;
            margin-bottom: $spacing-s;
        }  
    }

    &__charAmount {
        color: $typography-02;
        &--error {
            color: $red-light;
        }
    }

}