@import '../scss/variables';
@import '../scss/responsiveness';
@import '../scss/layout';

.errorPage {
    @include mainPadding;
    @include minPageHeight;

    display: flex;
    flex-direction: column;
    align-items: center;

    @include breakpoint(tablet-landscape-up) {
        flex-direction: row;
        justify-content: space-around;
    }
    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 500px;

        margin-bottom: $spacing-l;

        .button {
            margin-top: $spacing-m;
        }
    }

    &__404 {
        font-size: 10rem;
        @include breakpoint(large-mobile-up) {
            font-size: 20rem;
        }
        line-height: 100%;
        background: linear-gradient(0deg, rgba(241,158,63,0.45) 0%, rgba(247,142,30,1) 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        margin-bottom: $spacing-xxs;
    }

}