@import "../variables";
@import "../typography";
@import "../responsiveness";

$button-typography-color: #332B1F;

.button {
    @include p--bold;
    border-radius: 100px;
    padding: 10px $spacing-s; //guarantees height of 44px at least
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: none;
    transition: all 0.2s ease-in-out;
    @include breakpoint(desktop-up) {
        padding: $spacing-xxs $spacing-s;
    }

    &:hover {
        text-decoration: none;
    }

    &:disabled {
        opacity: 0.4;
        cursor: default;
    }

    &--primary {
        background: $orange-gradient;
        color: $button-typography-color;

        box-shadow: 0px 2px 15px -3px rgba(210, 109, 0, 0.37) , 0px 1px 6px -2px rgba(210, 109, 0, 0.11);

        &:hover {
            background: $orange-gradient--reverse;
            color: $button-typography-color;
            box-shadow: 0px 4px 15px -3px rgba(210, 109, 0, 0.37) , 0px 2px 6px -2px rgba(210, 109, 0, 0.20);
        }
        &:disabled {
            &:hover {
                background: $orange-gradient;
                color: $button-typography-color;

                box-shadow: 0px 2px 15px -3px rgba(210, 109, 0, 0.37) , 0px 1px 6px -2px rgba(210, 109, 0, 0.11);
            }
        }
    }
    &--secondary {
        color: $orange-light;
        background-color: transparent;
        border: 1px solid $orange-medium;
        &:hover {
            background-color: #F78E1E06;
            color: $orange-light;
        }
        &:active {
            background-color: #F78E1E0C;
            color: $orange-light;
        }
        &:disabled {
            &:hover {
                color: $orange-light;
                background-color: transparent;
            }
        }
    }
    &--text {
        color: $orange-light;
        background-color: transparent;
        border: none;
        &:hover {
            background-color: #F78E1E06;
            color: $orange-light;
        }
        &:active {
            background-color: #F78E1E0C;
            color: $orange-light;
        }
        &:disabled {
            &:hover {
                color: $orange-light;
                background-color: transparent;
            }
        }
    }
    &--approval,
    &--rejection,
    &--askchanges,
    &--approvalNoIcon {
        background-repeat: no-repeat;
        background-position-y: center;
        background-position-x: right;

        background-position-x: calc(100% - #{$spacing-s});
        padding-right: 47px;
        background-size: 20px;

        color: rgba(255, 255, 255, 0.85)
    }
    &--approval,
    &--approvalNoIcon {
        background-image: url('../../images/like.svg');
        background-color: $green-dark;

        &:hover {
            background-color: $green-medium;
            color: rgba(255, 255, 255, 0.85)
        }
        &:disabled {
            &:hover {
                background-color: $green-dark;
                color: rgba(255, 255, 255, 0.85);
            }
        }
    }
    &--approvalNoIcon {
        background-image: none;
        padding-right: $spacing-s;
    }
    &--rejection {
        background-image: url('../../images/dislike.svg');
        background-color: $red-dark;
        
        &:hover {
            background-color: $red-medium;
            color: rgba(255, 255, 255, 0.85)
        }
        &:disabled {
            &:hover {
                background-color: $red-dark;
                color: rgba(255, 255, 255, 0.85);
            }
        }
    }
    &--askchanges {
    	background-image: none;
    	background-color: $orange-dark;
    	padding-right: $spacing-s;
    	&:hover {
      	    background-color: $orange-medium;
            color: rgba(255, 255, 255, 0.85);
        }   
        &:disabled {
            &:hover {
                background-color: $orange-dark;
                color: rgba(255, 255, 255, 0.85);
            }
        }
    }
}
