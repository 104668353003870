@import '../variables';
@import '../typography';

.customAlert {
    margin-top: $menu-height-mobile;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include breakpoint(tablet-landscape-up) {
        margin-top: $menu-height-tablet-landscape-up;
    }

    .alert {
        border: none;
        margin: 0 $spacing-s;
        max-width: 100%;
        margin-bottom: $spacing-s !important;

        @include breakpoint(large-mobile-up) {
            max-width: 50%;
        }
        @include breakpoint(tablet-up) {
            min-width: 50%;
            max-width: 75%;
        }
    }
    .alert-heading {
        @include h3;
    }
    .alert-success {
        background-color: $green-light;
        p,
        .close span,
        .alert-heading {
            color: #283628;
        }
    }
    .alert-danger {
        background-color: $red-light;
        p,
        .close span,
        .alert-heading {
            color: #442228;
        }
    }
    .alert-warning {
        background-color: #F0D376;
        p,
        .close span,
        .alert-heading {
            color: #2C2614;
        }
    }
}