@import '../../scss/variables';
@import '../../scss/responsiveness';
@import '../../scss/layout';

.setVotingPeriod {
    @include mainPadding;
    @include minPageHeight;

    &__section {
        display: flex;
        flex-direction: column;

        margin-bottom: $spacing-l;

        p {
            margin-top: $spacing-s;
        }

        .button {
            margin-top: $spacing-s;
            align-self: center;
        }
    }
    &__fieldset {
        margin-right: $spacing-xxs;
        width: 75px;

        &:last-of-type {
            margin-right: 0;
        }
    }
    &__periodForm {
        display: flex;
    }
}

.block-buy-timeleft {
    color: #FFFFFF;

    display: flex;
    justify-content: center;
    align-items: center;
}
.timeleft-unit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-right: 1px solid #FFFFFF;
    padding: 0 .5rem;
}

.timeleft-unit--no-border {
    border-right: none;
}

.timeleft-unit div {
    font-size: 1rem;
    font-weight: 600;
}

.timeleft-unit span {
    font-size: 0.6rem;
    font-weight: 400;
}

@media screen and (min-width: 380px) {
    .timeleft-unit {
        padding: 0 .5rem;
    }
    .timeleft-unit div {
        font-size: 1.2rem;
    }
    .timeleft-unit span {
        font-size: .8rem;
    }
}
@media screen and (min-width: 576px) {
    .timeleft-unit div {
        font-size: 1.5rem;
    }
    .timeleft-unit {
        padding: 0 .8rem;
    }
}