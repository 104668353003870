@import '../../scss/variables';
@import '../../scss/typography';

.deliverableCard {
    background-color: $gray-dark;
    border: 1px solid $overlay-04;
    border-radius: $border-radius;
    padding: $spacing-s;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }


    &__actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        margin-bottom: $spacing-xs;

        width: 100%;

        .button {
            margin: 0 $spacing-xs 0 0 !important;
            align-self: flex-start;  
        }      
    }

    &__arrow {
        padding: $spacing-xxs;
        background-color: $gray-dark;
        border-radius: 5px;

        border: none;
        outline: none;

        &:first-of-type {
            margin-right: $spacing-xs;
        }

        &--down {
            svg {
                transform:rotate(180deg);
            }
        }

        &:hover {
            background-color: $orange-medium;
            cursor: pointer;

            svg path {
                fill: $gray-dark;
            }
        }
    }
    &__fieldset {
        max-width: 240px;
        margin-bottom: $spacing-xs;
    }

    &__swapCurrency {
        .swap_currency {
            display: flex;
            margin: $spacing-xs auto;  
        }
    }

    &__popover {
        background-color: $modal-background-color;
        .popover-header {
            background-color: $modal-background-color;
            border-bottom: 1px solid $overlay-04;
        }
        .popover-body {
            @include p;
        }
        .arrow::after {
            border-top-color: $modal-background-color;
        }
    }
}
