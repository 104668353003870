@import '../../../scss/responsiveness';

.editProfile {
    &__actions {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;

        @include breakpoint(tablet-landscape-up) {
            flex-direction: row;
        }
    }
}