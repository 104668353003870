@import '../../scss/typography';
@import '../../scss/variables';

@import '../../scss/components/Link.scss';
@import '../../scss/components/Button.scss';
@import '../../scss/components/Input.scss';

.singleDeliverable {
    border: 1px solid $overlay-06;
    border-radius: $border-radius;
    padding: $spacing-xs;
    &__id {
        margin-bottom: $spacing-xs;

        @include breakpoint(tablet-up) {
            margin: 0;
        }
    }
    &__status {
        @include h4;
        color: $typography-03;
        margin-left: $spacing-xs;
    }
    &__arrow {
        width: 25px;
        transition: all 0.5s ease-out;

        @include breakpoint(large-mobile-only) {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

        }
        &--up {
            transform: rotate(180deg);
        }
    }
    &__header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        position: relative;
        @include breakpoint(tablet-up) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        cursor: pointer;
    }
    &__body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    &__informationGroup {
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        @include breakpoint(tablet-up) {
            flex-direction: row;
        }
    }
    &__detail {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: $spacing-xs $spacing-m $spacing-m 0;
        &--main {
            margin: $spacing-xs;
            @include breakpoint(large-mobile-only) {
                margin-left: 0;
            }
        }
    }
    &__label {
        @include p--tiny;
    }
    &__info {
        @include h4;
    }
    &__actions {
        .button {
            margin: $spacing-xs $spacing-s $spacing-xs 0;
        }
    }
    .inlineLink {
        margin: $spacing-m;
    }
}