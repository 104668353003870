@import '../../scss/variables';
@import '../../scss/responsiveness';
@import '../../scss/layout';

.removeProfiles {
    @include mainPadding;
    @include minPageHeight;

    display: flex;
    flex-direction: column;

    .button {
        margin-top: $spacing-s;
        align-self: center;
    }
}