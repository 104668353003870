@import '../scss/responsiveness';
@import '../scss/variables';
@import '../scss/layout';

.loadingPage {
    @include mainPadding;
    @include minPageHeight;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__spinner {
        border: 16px solid  $overlay-04; /* Light grey */
        border-top: 16px solid $orange-medium; /* Blue */
        border-radius: 50%;
        width: 120px;
        height: 120px;
        animation: spin 2s linear infinite;
      }

      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
}