@import '../../scss/variables';
@import '../../scss/responsiveness';
@import '../../scss/layout';

.setMinMaxRequested {
    @include mainPadding;
    @include minPageHeight;

    &__section {
        display: flex;
        flex-direction: column;

        margin-bottom: $spacing-xs;

        p {
            margin-top: $spacing-xxs;
            margin-bottom: $spacing-s;
            text-align: center;
            font-size: 140%;
            font-weight: bold;
        }

        .button {
            margin-top: $spacing-s;
            margin-bottom: $spacing-l;
            align-self: center;

        }

        .swap_currency {
            margin-top: $spacing-xxs;
            margin-bottom: $spacing-xxs;  
            align-self: center;
        }
    }

    &__fieldset {
        margin-right: $spacing-xxs;
        width: 75px;
    }

}