@import "../variables";
@import "../typography";
@import "../responsiveness";

select {
    /* removes default dropdown arrow */
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    background-color: transparent;
    width: 100%;
    outline: none;
}

.select {
    border: 1px solid $overlay-02;
    color: $typography-02;
    border-radius: 8px;
    padding: $spacing-xxs;
    margin-right: $spacing-xs;

    cursor: pointer;

    background-image: url(../../images/select-arrow.svg);
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: calc(100% - 6px);

    &__option {
        @include p;
        color: $gray-dark;
    }

    &--error {
        border-color: $red-medium;
    }
}