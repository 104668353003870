@import '../scss/variables';
@import '../scss/responsiveness';
@import '../scss/typography';
@import '../scss/layout';

@import '../scss/components/Carousel';
@import '../scss/components/Tag';

@mixin minMargin {
    margin-right: $spacing-s;
    margin-left: $spacing-s;
}
.home {
    @include mainPadding;
    @include minPageHeight;

    padding-left: 0;
    padding-right: 0;

    h4 {
        color: $typography-02;
    }
    &__cover {
        background-image: url('../images/x-background.svg');
        background-repeat: no-repeat;
        background-position: right;
        background-size: contain;

        @include breakpoint(desktop-up) {
            background-image: url('../images/wax-gradient.svg');
        }
        min-height: 40vh;

        display: flex;
        flex-direction: column;
        justify-content: center;

        .button {
            align-self: flex-start;
            margin-top: $spacing-xs;
        }

        h1,
        h4,
        .button {
            @include minMargin;
            max-width: 600px;
            margin-bottom: $spacing-s;

            @include breakpoint(tablet-up) {
                margin-left: 10vw;
            }
        }
    }
    &__numbers {
        @include minMargin;

        display: flex;
        flex-direction: column;

        margin-top: $spacing-s;

        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            &:hover {
                transition: all 0.2s ease-in;
                text-decoration: none;
                h2 {
                    transform: scale(1.1);
                }
                h4 {
                    color: $orange-medium;
                }
            }
        }
    }
    &__funds {
        display: flex;
        flex-direction: column;
        @include breakpoint(tablet-up) {
            flex-direction: row;
            justify-content: center;

        }
    }
    &__proposalsNumbers {
        display: flex;
        flex-direction: column;
        margin-top: $spacing-s;

        @include breakpoint(tablet-up) {
            flex-direction: row;
            justify-content: center;

        }
    }
    &__number {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: $spacing-xs;

        h2, h3 {
            background: linear-gradient(0deg, rgba(241,158,63,0.45) 0%, rgba(247,142,30,1) 100%);
            background-clip: text;
            -webkit-text-fill-color: transparent;

            margin-bottom: $spacing-xxs;
        }

        @include breakpoint(tablet-up) {
            margin: 0 $spacing-m;
        }
    }
    &__proposalLifecycle {
        background-color: $overlay-01;
        border-radius: $border-radius;
        padding: $spacing-s;

        max-width: 1200px;
        margin: $spacing-s;

        @include breakpoint(desktop-up) {
            margin: $spacing-s auto;
        }
    }
    &__tabs {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include breakpoint(tablet-landscape-up) {
            flex-direction: row;
        }
        .active {
            outline: none;
            .tag--neutral {
                background-color: rgba($blue-dark, 1);
                color: $typography-01;
            }
            .tag--positive {
                background-color: rgba($green-dark, 1);
                color: $typography-01;
            }
            .tag--attention {
                background-color: rgba($orange-dark, 1);
                color: $typography-01;
            }
            .tag--negative {
                background-color: rgba($red-dark, 1);
                color: $typography-01;
            }
        }
    }
    &__tab {
        margin: $spacing-xxs;
        &--many {
            display: flex;
            flex-direction: column;
            align-items: center;

            @include breakpoint(desktop-up) {
                flex-direction: row;
            }
            .tag {
                margin-bottom: 0;

                &:last-of-type {
                    margin-top: $spacing-xxs;
                }
                @include breakpoint(desktop-up) {
                    margin-right: $spacing-xxs;

                    &:last-of-type {
                        margin-right: 0;
                        margin-top: 0;
                    }
                }
            }
        }
    }
    &__tabPane {
        margin: $spacing-s 0;
        overflow-x: auto;
        text-align: center;
        scrollbar-width: none;

        img {
            max-height: 400px;
            padding-bottom: $spacing-s;
        }
        &--large {
            img {
                max-height: 600px;
            }
        }
    }
    &__scrollMessage {

        display: flex;
        align-items: center;

        @include breakpoint(desktop-up) {
            display: none;
        }
        p {
            @include p--tiny;
            margin-bottom: 0;
        }
    }
}