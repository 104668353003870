@import '../../scss/variables';
@import '../../scss/typography';

.notifications {
    margin: $spacing-s 0;

    @include breakpoint(desktop-up) {
        margin: 0 $spacing-l 0 0;
    }

    &__icon {
        background-color: $gray-dark;
        border: none;
        border-radius: 0;
        padding: 0;
        display: flex;

        h4 {
            color: $typography-02;
            margin-bottom: 0;
        }


        svg {
            fill: $typography-02;
            margin-right: $spacing-xxs;
            margin-bottom: 2px;
        }

        &:hover {
            border: none;
            background-color: transparent;
            svg {
                fill: $typography-01;
            }
            h4 {
                color: $typography-01;
            }
        }
    }
    &__openDropdown {
        background-color: $modal-background-color;
        max-height: 50vh;
        border-radius: $border-radius;
        overflow-y: auto;
        padding: 0;
    }
    &__list {
        margin-bottom: 0;

        p {
            margin-bottom: 0;
            text-align: center;
        }
    }
    &__notification {
        @include p--bold;
        padding: 0;
        cursor: pointer;

        &:hover {
            background-color: $orange-light;

            .notifications__link {
                text-decoration: none;
            }
            .notifications__id,
            .notifications__text {
                color: $gray-dark;
            }
        }

        &--none {
            background-color: $modal-background-color;
            color: $typography-02;
            text-align: center;
            cursor: default;

            &:hover {
                cursor: default;
                background-color: $modal-background-color;
            }
        }
    }
    &__link {
        display: block;
        padding: $spacing-s;
    }
    &__id {
        @include p--bold;
        color: $orange-medium;

    }
    &__text {
        @include p;
    }
}