@import '../../scss/layout';
@import '../../scss/responsiveness';

.editProposal {
    @include mainPadding;
    @include minPageHeight;

    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    .button {
        margin-top: $spacing-s;
    }

}