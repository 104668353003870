@import '../scss/variables';
@import '../scss/responsiveness';
@import '../scss/typography';
@import '../scss/layout';

@import '../scss/components/Input';
@import '../scss/components/Select';
@import '../scss/components/Button';
@import '../scss/components/Link';
@import '../scss/components/Pagination';

.genericProposals {
    @include minPageHeight;

    max-width: $max-screen-width;
    margin: 0 auto;

    &__filtersGroup {
        @include breakpoint(tablet-landscape-up) {
            display: block !important;
        }
    }

    &__filters {
        display: flex;
        flex-direction: column;

        .button {
            margin: $spacing-xs auto;
        }
    }
    &__filter {
        @include breakpoint (mobile-only) {
            text-align: center;
        }
        .button {
            @include breakpoint(tablet-landscape-up) {
                display: none;
            }
        }
        .collapse {
            @include breakpoint(tablet-landscape-up) {
                display: block;
            }
        }
    }
    &__search {
        max-width: 500px;
    }
    &__actions {
        display: flex;
        flex-direction: column-reverse;
        margin: $spacing-xs 0;
        align-items: center;

        @include breakpoint(large-mobile-up) {
            flex-direction: row-reverse;
            justify-content: space-between;
        }
    }
    &__orderBy {
        margin: $spacing-xxs 0;
    }
    &__createProposal {
        margin: $spacing-s 0;

        @include breakpoint(large-mobile-up) {
            margin: 0;
        }
        p {
            @include p;
            margin: 0;
            color: $typography-02;
        }
    }
}
