@import '../../scss/layout';

@import '../../scss/components/Link';

.createProposal {
    @include mainPadding;
    @include minPageHeight;

    text-align: center;

    h3 {
        margin-top: $spacing-l;
    }

}